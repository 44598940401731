<template>
  <el-dialog
    title="Vendas vinculadas ao produto"
    v-model="shouldShow"
    width="90%"
  >
    <el-descriptions
      class="mt-2"
      :column="2"
      size="small"
      border
      title="Dados do Produto"
    >
      <template #extra>
        <el-input
          v-model="searchName"
          v-on:keyup.enter="fetchSells()"
          placeholder="Digite e pressione enter"
        />
      </template>
      <el-descriptions-item>
        <template #label>Produto</template>
        {{ product?.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Categoria</template>
        {{ product?.category?.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Montagem</template>
        {{ printMountedProducts() }}
      </el-descriptions-item>
    </el-descriptions>

    <el-table
      stripe
      :data="Sells"
      :cell-style="() => 'text-align:center;'"
      v-loading="isLoading"
    >
      <el-table-column prop="code" label="código"></el-table-column>
      <el-table-column prop="origin" label="açougue"></el-table-column>
      <el-table-column prop="product_name" label="nome"></el-table-column>
      <el-table-column
        prop="total_sells"
        label="total de vendas"
      ></el-table-column>
      <el-table-column label="ações" :min-width="40" fixed="right">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-popconfirm
              @confirm="unlinkSell(cat.row)"
              title="
              Desvincular venda?
            "
              ><template #reference>
                <el-button
                  onlyIcon
                  type="warning"
                  size="small"
                  title="Desvincular venda deste produto"
                >
                  <i class="el-icon-circle-close"></i></el-button></template
            ></el-popconfirm>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      class="mt-1"
      background
      layout="prev, pager, next"
      :page-count="sells?.lastPage"
    >
    </el-pagination>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Fechar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { ElNotification } from "element-plus";
export default {
  name: "ProductSellsModal",
  props: ["product", "showModal"],
  data: () => {
    return {
      isLoading: false,
      searchName: "",
      currentPage: 1,
      sells: null,
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
      }),
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }),
      numberFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }),
    };
  },
  emits: ["close-modal"],
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    Sells() {
      return this.sells?.data || [];
    },
  },
  watch: {
    showModal(v) {
      if (v) {
        this.searchName = "";
        this.fetchSells();
      }
    },
    currentPage() {
      this.fetchSells();
    },
  },
  methods: {
    mountedProducts() {
      return (
        this.product?.mounted
          .map((m) => ({
            uid: m.id,
            product: {
              name: `${this.product?.name} ${m.cutting ?? ""} ${
                m.composition ?? ""
              } ${m.storage ?? ""}`,
            },
          }))
          ?.flat() || []
      );
    },
    printMountedProducts() {
      const mounteds = this.mountedProducts();

      return mounteds?.map((m) => `${m.product?.name?.trim()}`)?.join(", ");
    },
    unlinkSell(sell) {
      if (sell)
        this.updateSell({
          uid: sell.uid,
          product_id: null,
          product_mounted_id: null,
        });
    },
    updateSell(sell) {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}sells/${sell.uid}`);

      fetch(url, {
        credentials: "include",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sell),
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then(() => {
          this.notifySuccess(
            "Vendas desvinculadas com sucesso",
            "todas as vendas agora estão disponíveis na tela de vinculação"
          );
          this.fetchSells();
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    fetchSells() {
      this.isLoading = true;
      const url = new URL(
        `${this.$store.state.apiUrl}products/${this.product?.uid}/sells/grouped`
      );

      url.search = new URLSearchParams({
        searchName: this.searchName,
        pagination: this.currentPage,
        hasReference: true,
        product_id: this.product?.uid,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.sells = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    notifySuccess(title, message) {
      ElNotification.success({ title, message });
    },
  },
};
</script>
<style scoped>
.mt-1 {
  margin-top: 8px;
}
</style>