<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-table
        :data="MountedProducts"
        style="width: 100%"
        v-if="showMountList"
      >
        <el-table-column prop="storage" label="armazenamento"></el-table-column>
        <el-table-column prop="cutting" label="corte"></el-table-column>
        <el-table-column
          prop="composition"
          label="composição"
        ></el-table-column>
        <el-table-column header-align="right" width="50px" label="remover">
          <template #default="m">
            <el-button
              onlyIcon
              size="mini"
              type="primary"
              @click="removeProductMount(m.row)"
            >
              <i class="el-icon-delete"></i
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-else>
        <el-select v-model="mounting.storage" size="medium" clearable>
          <template #prefix>Forma de armazenamento:</template>
          <el-option
            v-for="item in FilterPropertyByType('storage')"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-select v-model="mounting.cutting" size="medium" clearable>
          <template #prefix>Forma de corte:</template>
          <el-option
            v-for="item in FilterPropertyByType('cutting')"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-select v-model="mounting.composition" size="medium" clearable>
          <template #prefix>Composição do Produto:</template>
          <el-option
            v-for="item in FilterPropertyByType('composition')"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex" v-if="!showMountList">
        <el-button type="text" class="button" @click="showMountList = true"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="updateProduct()"
          >Salvar</el-button
        >
      </el-row>
      <el-row justify="space-between" type="flex" v-else>
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="showMountList = false"
          >Nova Montagem</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["product", "showModal"],
  //components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      isLoadingSave: false,
      showMountList: true,
      mounting: {},
    };
  },
  computed: {
    ModalTitle() {
      return `Montagem do produto ${this.product?.name || "desconhecido"}`;
    },
    MountedProducts() {
      return this.product?.mounted || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    removeProductMount(mount) {
      this.mounting = mount;
      this.updateProduct();
    },
    checkIfProductMountExist() {
      const c = this.mounting.cutting;
      const s = this.mounting.storage;
      const comp = this.mounting.composition;
      return this.product?.mounted?.some(
        (m) => m.cutting === c && m.storage === s && m.composition === comp
      );
    },
    alertUserProductMountExist() {
      this.$alert(
        "você não pode cadastrar composições iguais",
        "Essa composição do produto já existe",
        {
          type: "error",
          title: "Essa composição do produto já existe",
          message: "você não pode cadastrar composições iguais",
        }
      );
    },
    FilterPropertyByType(type) {
      return Array.isArray(this.product?.properties)
        ? this.product?.properties.filter((e) => e.type === `${type}`)
        : [];
    },
    changeViewList(showMountList = true) {
      this.showMountList = showMountList;
    },
    updateProduct() {
      if (this.checkIfProductMountExist() && !("id" in this.mounting)) {
        this.alertUserProductMountExist();
        return;
      }

      this.isLoadingSave = true;

      fetch(`${this.$store.state.apiUrl}products/${this.product.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ mounting: this.mounting }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.showMountList = true;
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o produto",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.mounting = {};
        });
    },
  },
  name: "ProductCategoryModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-switch {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>