<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Lista de Produtos</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-autocomplete
              value-key="name"
              clearable
              class="select-category"
              :value="CategoryName"
              :fetch-suggestions="fetchProductCategories"
              placeholder="Selecione uma categoria"
              @select="selectCategory"
            >
              <template #prepend>Categoria de Produto:</template>
            </el-autocomplete>
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openAddProductModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Products"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="category.name" label="categoria">
      </el-table-column>
      <el-table-column label="preço mínimo">
        <template #default="scope">{{
          ProductPrice(scope.rows, "min")
        }}</template>
      </el-table-column>
      <el-table-column label="preço ideal">
        <template #default="scope">{{
          ProductPrice(scope.rows, "std")
        }}</template>
      </el-table-column>
      <el-table-column label="ações" :width="130" fixed="right">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                title="Editar produto"
                type="primary"
                circle
                @click="openAddProductModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                title="Montar uma nova combinação do produto"
                type="primary"
                circle
                @click="openMountProductModal(cat.row)"
              >
                <i class="el-icon-set-up"></i
              ></el-button>
              <el-button
                onlyIcon
                title="Vendas do produto"
                type="primary"
                circle
                @click="openProductSellsModal(cat.row)"
              >
                <i class="el-icon-goods"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteProduct(cat.row)"
                title="
              Remover produto?
            "
                ><template #reference>
                  <el-button
                    onlyIcon
                    type="danger"
                    title="Remover produto"
                    circle
                  >
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <product-modal
      :showModal="showProductModal"
      :product="product"
      @close-modal="showProductModal = false"
      @should-update="fetchProducts"
    ></product-modal>
    <product-sells-modal
      v-if="product"
      :showModal="showProductSellsModal"
      :product="product"
      @close-modal="showProductSellsModal = false"
    ></product-sells-modal>
    <mount-product-modal
      :showModal="showMountProductModal"
      :product="product"
      @close-modal="showMountProductModal = false"
      @should-update="fetchProducts"
    ></mount-product-modal>
  </el-card>
</template>

<script>
import ProductModal from "./modals/Product.vue";
import MountProductModal from "./modals/MountProduct.vue";
import ProductSellsModal from "./modals/ProductSellsModal.vue";
export default {
  name: "ProductCategory",
  components: { ProductModal, MountProductModal, ProductSellsModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    products: null,
    product: null,
    category: null,
    showMountProductModal: false,
    showProductSellsModal: false,
    showProductModal: false,
  }),
  mounted() {
    this.fetchProducts();
  },
  computed: {
    CategoryName() {
      return this.category?.name || "";
    },
    Products() {
      return (
        this.products?.filter((p) =>
          this.category ? p.category?.uid === this.category?.uid : true
        ) || []
      );
    },
  },
  methods: {
    deleteProduct(product) {
      fetch(`${this.$store.state.apiUrl}products/${product.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchProducts();
          this.$notify({
            title: "Produto removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o produto",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    ProductPrice(p, type) {
      if (type === "min")
        return `M: R$ ${p?.price?.male_min || "0,00"} F: R$ ${
          p?.price?.female_min || "0,00"
        }`;
      else
        return `M: R$ ${p?.price?.male_std || "0,00"} F: R$ ${
          p?.price?.female_std || "0,00"
        }`;
    },
    openAddProductModal(e) {
      (this.product = e), (this.showProductModal = true);
    },
    fetchProductCategories(query, callback) {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => callback(json))
        .catch(() => callback([]));
    },
    selectCategory(c) {
      this.category = c;
    },
    openMountProductModal(e) {
      (this.product = e), (this.showMountProductModal = true);
    },
    openProductSellsModal(e) {
      (this.product = e), (this.showProductSellsModal = true);
    },
    updateDataInModal() {
      if (this.product) {
        this.product = this.products?.find((p) => p.uid === this.product.uid);
      }
    },
    fetchProducts() {
      fetch(`${this.$store.state.apiUrl}products`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.products = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false | this.updateDataInModal()));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>

<style>
.select-category {
  margin-right: 8px;
  padding: 4px 0px;
}
</style>